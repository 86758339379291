import classes from './CalendarProviderSection.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import useTheme from '@lobox/uikit/utils/useTheme';
import {
  Cookies,
  QueryKeys,
  schedulesApi,
  useReactQuery,
  useTranslation,
} from '@lobox/utils';
import { CalendarProviderItem } from './CalendarProviderItem';
import type { ProviderSingleData, ProviderType } from '../utils/type';

type Props = {
  list: Array<ProviderSingleData>;
  type: ProviderType;
  queryKey: string;
  cookieKey: string;
  isEdit?: boolean;
};

export const buttonLabelKeyMapper: Record<ProviderType, string> = {
  GOOGLE: 'google',
  OFFICE_365: 'microsoft',
  OUTLOOK: 'outlook',
  EXCHANGE: 'exchange',
  ICLOUD: 'icloud',
};

export const CalendarProviderSection: React.FC<Props> = ({
  list = [],
  type,
  queryKey,
  cookieKey,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  const { data = [] } = useReactQuery<
    Array<{ url: string; type: ProviderType }>
  >({
    action: {
      key: QueryKeys.getAllProvidersKeys,
      apiFunc: schedulesApi.getAllProviderUrls,
    },
    config: {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  });

  const url = data?.find((item) => item?.type === type)?.url;

  const handleClick = () => {
    if (!url) return;
    Cookies.set(cookieKey, type);
    window.location.href = url;
  };

  return (
    <Flex>
      <Flex className={classes.itemsContainer}>
        {list?.length ? (
          list?.map((item) => (
            <CalendarProviderItem
              data={item}
              queryKey={queryKey}
              isEdit={isEdit}
            />
          ))
        ) : (
          <CalendarProviderItem
            data={{
              connected: false,
              type,
            }}
            isEmpty
            queryKey={queryKey}
          />
        )}
      </Flex>
      <Button
        schema={isDark ? 'secondary-dark' : 'primary-light'}
        label={t('add').concat(
          ' ',
          t(buttonLabelKeyMapper[type]),
          ' ',
          t('account')
        )}
        leftIcon="plus"
        leftType="far"
        leftSize={19}
        className={classes.button}
        onClick={handleClick}
      />
    </Flex>
  );
};
