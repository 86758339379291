import { useEffect } from 'react';
import {
  Cookies,
  QueryKeys,
  schedulesApi,
  useReactMutation,
  useUrlQuery,
} from '@lobox/utils';
import { useSetEvents } from '@/shared/hooks/useSetEvents';
import { useQueryClient } from '@tanstack/react-query';
import type { ProviderType } from './type';

const CALENDAR_INTEGRATION_PROVIDER_TYPE_KEY =
  'CALENDAR_INTEGRATION_PROVIDER_TYPE_KEY';

/**
 *
 * @param type
 * @param callback This is called when the user is redirected to Lobox after oauth operations
 */
export function useObserveToAddProvider(callback?: () => any): {
  cookieKey: string;
} {
  const cookieKey = CALENDAR_INTEGRATION_PROVIDER_TYPE_KEY;
  const queryClient = useQueryClient();
  const query = useUrlQuery();
  const authorizationCode = decodeURIComponent(query.get('code') || '');
  const { fetchEvents } = useSetEvents();
  const { mutate: addProvider } = useReactMutation({
    apiFunc: schedulesApi.addProvider,
  });
  useEffect(() => {
    if (!authorizationCode) return;
    if (callback) return;
    const type = Cookies.get(cookieKey) as ProviderType;
    if (!type) return;
    addProvider(
      {
        authorizationCode,
        type,
      },
      {
        onSettled: () => {
          const currentURL = window.location.href;
          if (!currentURL.includes('?')) return;
          const newURL = currentURL.split('?')[0];
          window.history.replaceState({}, document.title, newURL);
          Cookies.remove(cookieKey);
          queryClient.refetchQueries(QueryKeys.getAllProviders);
        },
        onSuccess: () => {
          fetchEvents();
        },
      }
    );
  }, [authorizationCode]);

  useEffect(() => {
    if (!callback) return;
    if (!authorizationCode) return;
    callback();
  }, [authorizationCode]);

  return { cookieKey };
}
