import classes from './ProviderIcon.module.scss';

import Flex from '@lobox/uikit/Flex';
import React from 'react';
import GOOGLE from '../../../../svg/GoogleCalendar';
import OUTLOOK from '../../../../svg/Outlook';
import OFFICE_365 from '../../../../svg/Office365';
import EXCHANGE from '../../../../svg/Exchange';
import ICLOUD from '../../../../svg/ICloud';
import type { ProviderType } from '../utils/type';

export const provderIconNameMapper: Record<ProviderType, React.FC> = {
  GOOGLE,
  OFFICE_365,
  OUTLOOK,
  EXCHANGE,
  ICLOUD,
};

type Props = {
  type: ProviderType;
};

export const ProviderIcon: React.FC<Props> = ({ type }) => {
  const Comp = provderIconNameMapper[type];
  return (
    <Flex className={classes.wrapper}>
      <Comp />
    </Flex>
  );
};
