import React, { useState } from 'react';
import AccordionItem from './Accordion.Item';

type CallBackProps = {
  isExpanded?: boolean;
  setExpanded: () => void;
};

export interface AccordionProps {
  data: Array<{
    id: number | string;
    header: ({ isExpanded, setExpanded }: CallBackProps) => React.ReactNode;
    content: ({ isExpanded, setExpanded }: CallBackProps) => React.ReactNode;
    divider?: boolean;
    isExpanded?: boolean;
  }>;
  contentClassName?: string;
  dividerClassName?: string;
  itemClassName?: string;
  singleOpen?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  data,
  contentClassName,
  dividerClassName,
  itemClassName,
  singleOpen,
}) => {
  const initialExpandedIndexes = singleOpen
    ? data.findIndex(({ isExpanded }) => isExpanded)
    : data.map(({ isExpanded }) => isExpanded);
  const [expandedIndexes, setExpandedIndexes] = useState<
    Array<boolean | undefined> | number | boolean
  >(initialExpandedIndexes);

  return (
    <>
      {data.map(({ id, content, header, divider }, index) => {
        const isExpanded = singleOpen
          ? index === expandedIndexes
          : // @ts-ignore
            expandedIndexes[index];

        const onClick = () => {
          if (singleOpen) {
            setExpandedIndexes(isExpanded ? false : index);
          } else {
            setExpandedIndexes((arr) => {
              // @ts-ignore
              const newArr = [...arr];
              // @ts-ignore
              newArr[index] = !arr[index];

              return newArr;
            });
          }
        };

        return (
          <AccordionItem
            key={`${id} ${isExpanded ? 1 : 2}`}
            isExpanded={isExpanded}
            onClick={onClick}
            header={header({ isExpanded, setExpanded: onClick })}
            content={
              isExpanded ? content({ isExpanded, setExpanded: onClick }) : null
            }
            divider={divider}
            contentClassName={contentClassName}
            dividerClassName={dividerClassName}
            itemClassName={itemClassName}
          />
        );
      })}
    </>
  );
};

export default Accordion;
