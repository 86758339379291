import classes from './CalendarProviderItem.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Switch from '@lobox/uikit/Switch';
import Tooltip from '@lobox/uikit/Tooltip';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import useTheme from '@lobox/uikit/utils/useTheme';
import Spinner from '@lobox/uikit/Spinner';
import IconButton from '@lobox/uikit/Button/IconButton';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import useToast from '@lobox/uikit/Toast/useToast';
import cnj from '@lobox/uikit/utils/cnj';
import {
  schedulesApi,
  useReactMutation,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import { useSetEvents } from '@/shared/hooks/useSetEvents';
import { ProviderIcon } from './ProviderIcon';
import type { ProviderSingleData, ProviderType } from '../utils/type';

type Props = {
  data: ProviderSingleData;
  isEmpty?: boolean;
  queryKey: string;
  isEdit?: boolean;
};

const itemLabelKeyMapper: Record<ProviderType, string> = {
  GOOGLE: 'google_calendar',
  OFFICE_365: 'office_365_calendar',
  OUTLOOK: 'outlook_calendar',
  EXCHANGE: 'exchange_calendar',
  ICLOUD: 'icloud_calendar',
};

export const CalendarProviderItem: React.FC<Props> = ({
  data,
  isEmpty,
  queryKey,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { isDark } = useTheme();
  const { isMoreThanTablet } = useMedia();
  const isActive = data?.connected;
  const { replace, refetch } = useUpdateQueryData(queryKey);
  const { fetchEvents } = useSetEvents(false);
  const { openConfirmDialog } = useOpenConfirm();
  const toast = useToast();

  const { mutate: connectMutation, isLoading: isLoadingConnect } =
    useReactMutation({
      apiFunc: schedulesApi.connectProvider,
    });
  const { mutate: disconnectMutation, isLoading: isLoadingDisconnect } =
    useReactMutation({
      apiFunc: schedulesApi.disconnectProvider,
    });

  const { mutate: remove } = useReactMutation({
    apiFunc: schedulesApi.removeCalendarProvider,
  });

  const toggleActive = () => {
    const func = isActive ? disconnectMutation : connectMutation;
    func(
      {
        id: data?.id,
      },
      {
        onSuccess: () => {
          replace({ ...data, connected: !isActive } as Props['data']);
          refetch();
          fetchEvents();
        },
      }
    );
  };
  const handleRemove = () => {
    openConfirmDialog({
      title: t('remove_account'),
      message: (
        <Flex className={classes.messageWrap}>
          <Typography>{t('are_you_sure_want_remove')}</Typography>&nbsp;
          <Typography font="bold">{data?.externalUserName}</Typography>
          &nbsp;
          <Typography>{t('account_lower')}</Typography>&nbsp;
          <Typography>{t('from_your')}</Typography>&nbsp;
          <Typography font="bold">
            {t(itemLabelKeyMapper[data?.type])}
          </Typography>
          &nbsp;
          <Typography>{t('integration')}?</Typography>
        </Flex>
      ),

      confirmButtonText: t('remove'),
      cancelButtonText: t('cancel'),
      confirmCallback: () => {
        remove(
          { id: data.id },
          {
            onSuccess: () => {
              toast({
                type: 'success',
                icon: 'check-circle',
                title: t('account_removed'),
                message: () => (
                  <Flex className={classes.messageWrap}>
                    <Typography font="bold">
                      {data?.externalUserName}
                    </Typography>
                    &nbsp;
                    <Typography>{t('account_lower')}</Typography>&nbsp;
                    <Typography>{t('removed_from_your')}</Typography>&nbsp;
                    <Typography font="bold">
                      {t(itemLabelKeyMapper[data?.type])}
                    </Typography>
                    &nbsp;
                    <Typography>{t('integration')}</Typography>
                  </Flex>
                ),
              });
              refetch();
            },
          }
        );
      },
    });
  };

  const isLoading = isLoadingConnect || isLoadingDisconnect;

  return (
    <Flex className={classes.wrapper}>
      <Flex className={classes.detailsContainer}>
        {data?.id && isEdit && (
          <IconButton
            onClick={handleRemove}
            name="times"
            colorSchema="transparent"
            size="md20"
            className={classes.deleteButton}
          />
        )}
        <Flex className={classes.icon}>
          <ProviderIcon type={data?.type} />
        </Flex>
        <Flex className={cnj(classes.textsContainer, isEmpty && classes.empty)}>
          <Typography
            color={
              !isEmpty
                ? 'primaryText'
                : isDark
                ? 'disabledGrayDark'
                : 'graphene_60'
            }
            size={15}
            height={17.58}
            font="700"
          >
            {t(itemLabelKeyMapper[data?.type])}
          </Typography>
          {!isEmpty && (
            <Typography
              color="primaryDisabledText"
              size={12}
              height={14}
              font="400"
              isTruncated
            >
              {data?.externalUserName}
            </Typography>
          )}
        </Flex>
      </Flex>

      {!isEmpty && (
        <Flex className={classes.statusBox}>
          {!isMoreThanTablet && (
            <Typography
              color={isActive ? 'primaryText' : 'secondaryDisabledText'}
              size={15}
              height={21}
              font="400"
            >
              {t(isActive ? 'connected' : 'disconnected')}
            </Typography>
          )}
          {isLoading ? (
            <Spinner size={20} />
          ) : (
            <Tooltip
              placement="top"
              trigger={
                <Switch
                  onChange={toggleActive}
                  value={isActive}
                  className={classes.switch}
                />
              }
            >
              <Typography font="400" size={13} color="muteMidGray_disabledGray">
                {t(isActive ? 'connected' : 'disconnected')}
              </Typography>
            </Tooltip>
          )}
        </Flex>
      )}
    </Flex>
  );
};
