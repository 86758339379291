import * as React from "react";

const ICloud: React.FC = (props) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7592 0.231142C12.6134 0.233517 11.4888 0.548652 10.5009 1.14419C9.5131 1.73972 8.69761 2.5942 8.13821 3.61986C7.65671 3.37937 7.12831 3.25403 6.59275 3.25326C5.75335 3.25552 4.94203 3.56352 4.30348 4.12233C3.66492 4.68114 3.24067 5.45442 3.10638 6.30426C2.21971 6.68504 1.46254 7.32609 0.93001 8.14688C0.397477 8.96766 0.113334 9.93156 0.113281 10.9175C0.114532 12.2435 0.629185 13.5148 1.54409 14.4518C2.45899 15.3888 3.69925 15.9148 4.99218 15.9142C5.19679 15.9123 5.40108 15.8972 5.60383 15.869H19.1602C19.2314 15.8743 19.3027 15.8778 19.3741 15.8796C19.4421 15.8776 19.51 15.8741 19.5779 15.869H19.9025V15.8442C21.0063 15.7104 22.024 15.1638 22.7628 14.3082C23.5016 13.4525 23.9101 12.3473 23.9109 11.202V11.193C23.9083 10.1053 23.538 9.0525 22.8636 8.21575C22.1892 7.37899 21.2529 6.81061 20.2158 6.60839C20.1542 4.8948 19.4468 3.27254 18.2426 2.08308C17.0383 0.893618 15.4311 0.229726 13.7592 0.231142Z"
      fill="url(#paint0_linear_15393_104098)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_15393_104098"
        x1="23.9832"
        y1="11.2147"
        x2="0.104267"
        y2="10.8936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3E82F4" />
        <stop offset="1" stopColor="#93DCF7" />
      </linearGradient>
    </defs>
  </svg>
);
export default ICloud;
