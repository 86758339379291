import React from 'react';
import Flex from '../Flex';
import AnimatedView from '../Layout/AnimatedView';
import Divider from '../Divider';
import cnj from '../utils/cnj';
import classes from './Accordion.Item.module.scss';

export interface AccordionItemProps {
  isExpanded?: boolean;
  onClick: (...args: any[]) => any;
  header: React.ReactNode;
  content: React.ReactNode;
  divider?: boolean;
  contentClassName?: string;
  dividerClassName?: string;
  itemClassName?: string;
}

const AccordionItem = ({
  isExpanded,
  onClick,
  header,
  content,
  divider,
  contentClassName,
  dividerClassName,
  itemClassName,
}: AccordionItemProps): JSX.Element => {
  return (
    <Flex className={cnj(itemClassName, isExpanded && classes.isExpanded)}>
      <Flex onClick={onClick}>{header}</Flex>
      <AnimatedView className={contentClassName} isVisible={isExpanded}>
        {content}
      </AnimatedView>
      {divider && (
        <Divider className={cnj(classes.divider, dividerClassName)} />
      )}
    </Flex>
  );
};

export default AccordionItem;
