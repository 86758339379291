import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useMeasure, getAutoHeightDuration } from '@lobox/utils';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import classes from './AnimatedView.component.module.scss';

export interface AnimatedViewProps {
  isVisible?: boolean;
  ease?: any;
  duration?: number;
  className?: string;
  children?: React.ReactNode;
  variants?: {
    open: any;
    collapsed: any;
  };
}

const AnimatedView: React.FC<AnimatedViewProps> = ({
  duration,
  ease = 'easeOut',
  variants = {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  isVisible,
  children,
  className,
  ...rest
}) => {
  const ref = useRef(null);
  const bounds = useMeasure(ref);

  return (
    <motion.div
      className={cnj(classes.animatedViewWrapper, className)}
      inherit={false}
      initial={isVisible ? 'open' : 'collapsed'}
      animate={isVisible ? 'open' : 'collapsed'}
      variants={variants}
      transition={{
        ease,
        duration:
          typeof duration === 'undefined'
            ? getAutoHeightDuration(bounds.height) / 1000
            : duration,
      }}
      {...rest}
    >
      {typeof children === 'function' ? (
        children(ref)
      ) : (
        <Flex ref={ref}>{children}</Flex>
      )}
    </motion.div>
  );
};

export default AnimatedView;
