import * as React from "react";

const SvgComponent: React.FC = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#046DB2"
      d="M11.165.5.705 2.446v13.743l10.46 2.311V.5ZM7.798 5.132v1.5l-2.43.214V8.15h2.285v1.499H5.368v1.676l2.511.258v1.499l-3.941-.483V5.59l3.86-.458ZM12.072 4.134v1.434l.369.009c.373.01.865.525 1.096.925.093.16-.258.494-.258.494l-.666-.408a3.808 3.808 0 0 0-.54-.053V8.92l.991.98c.393.605-.12.431-.992-.196v2.779c.212-.023.416-.094.565-.245l1.763-1.785c.942-.61.002.97-1.613 2.688-.158.168-.396.252-.715.29v1.54h6.106c.62 0 1.118-.444 1.118-.996V5.13c0-.552-.498-.996-1.118-.996h-6.106Zm4.476 1.423c.386.001.845.025 1.38.04 0 0 .046.73 0 2.343-.01.373-.525.864-.925 1.096-.16.093-.494-.258-.494-.258l.408-.666c.074-.445.098-.997-.107-1.441-.314-.144-1.057-.28-1.44.107l-1.764 1.785c-.942.61-.002-.969 1.613-2.688.247-.263.686-.32 1.329-.318ZM14.901 8.74c.33.016 1.493.827 2.729 1.988.42.396.317 1.282.279 2.71 0 0-.732.045-2.343 0-.374-.011-.866-.525-1.097-.925-.093-.161.258-.495.258-.495l.666.409c.445.073.997.097 1.44-.108.145-.314.282-1.057-.106-1.44l-1.785-1.763c-.172-.265-.17-.381-.041-.376Z"
    />
  </svg>
);
export default SvgComponent;
