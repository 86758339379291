import classes from './EventsIntegration.component.module.scss';

import React, { useMemo } from 'react';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';
import { QueryKeys, schedulesApi, useReactQuery } from '@lobox/utils';
import { CalendarProviderSection } from './Partials/CalendarProviderSection';
import { providers } from './utils/type';
import type { ProviderSingleData, ProviderType } from './utils/type';
import { useObserveToAddProvider } from './utils/useObserveToAddProvider';

type Props = {
  classNames?: {
    wrapper?: string;
  };
  isEdit?: boolean;
};

export const EventsIntegration: React.FC<Props> = ({ classNames, isEdit }) => {
  const queryKey = QueryKeys.getAllProviders;
  const { cookieKey } = useObserveToAddProvider();

  const { data = [] } = useReactQuery<Array<ProviderSingleData>>({
    action: {
      apiFunc: schedulesApi.getAllProviders,
      key: queryKey,
    },
  });

  const groupedData = useMemo(() => {
    const obj: Record<ProviderType, Array<ProviderSingleData>> = {} as any;
    providers.forEach((provider) => {
      obj[provider] = [];
    });
    return data?.reduce((acc, cur) => {
      acc[cur?.type].push(cur);
      return acc;
    }, obj);
  }, [data]);

  const sectionNames = Object.keys(groupedData) as ProviderType[];

  return (
    <Flex className={cnj(classNames?.wrapper, classes.wrapper)}>
      {sectionNames?.map((sectionName, index) => (
        <Flex>
          <CalendarProviderSection
            list={groupedData[sectionName]}
            type={sectionName}
            queryKey={queryKey}
            cookieKey={cookieKey}
            isEdit={isEdit}
          />
          {sectionNames?.length !== index + 1 && (
            <Divider className={classes.divider} />
          )}
        </Flex>
      ))}
    </Flex>
  );
};
