export const providers = [
  "GOOGLE",
  "OUTLOOK",
  "OFFICE_365",
  "EXCHANGE",
  "ICLOUD",
] as const;

export type ProviderType = (typeof providers)[number];

export type ProviderSingleData = {
  connected: boolean;
  externalUserName?: string;
  type: ProviderType;
  id?: string;
};
