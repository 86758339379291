import useMedia from '@lobox/uikit/utils/useMedia';
import { ProfilePanelStateType, routeNames } from '@lobox/utils';
import { useGlobalDispatch, useGlobalState } from '@lobox/utils';
import { useHistory } from '@lobox/utils'

export const useProfilePanelState = () => {
  const state = useGlobalState('profilePanelState');
  const { isMoreThanTablet } = useMedia();
  const globalDispatch = useGlobalDispatch();
  const history = useHistory();

  const setProfilePanelState = (_state: ProfilePanelStateType) => {
    if (isMoreThanTablet) {
      globalDispatch({
        type: 'SET_SHOW_PROFILE_PANEL',
        payload: _state,
      });
    } else {
      history.push(routeNames.settingsScheduleCalendar);
    }
  };

  const closePanel = () => {
    if (isMoreThanTablet) {
      globalDispatch({
        type: 'SET_SHOW_PROFILE_PANEL',
        payload: false,
      });
    }
  };

  return { state, setProfilePanelState, closePanel };
};
