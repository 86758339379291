import * as React from 'react';

const SvgComponent: React.FC = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M13.737 4.263 9.474 3.79l-5.21.473L3.788 9l.474 4.737L9 14.329l4.737-.592.474-4.855-.474-4.619Z"
      />
      <path
        fill="#1A73E8"
        d="M6.207 11.612c-.355-.24-.6-.588-.734-1.05l.822-.339c.075.284.205.504.391.66.185.157.41.234.673.234.269 0 .5-.082.693-.245a.783.783 0 0 0 .29-.624.774.774 0 0 0-.306-.632c-.204-.164-.46-.246-.765-.246h-.475v-.813h.427c.262 0 .484-.071.664-.213a.703.703 0 0 0 .27-.584c0-.22-.08-.396-.242-.527a.936.936 0 0 0-.612-.198c-.242 0-.433.064-.575.193-.143.13-.246.288-.31.475l-.814-.339c.107-.305.305-.575.595-.808.29-.234.661-.35 1.111-.35.333 0 .633.063.898.192.265.13.473.308.624.535.15.229.225.485.225.769 0 .29-.07.535-.21.736-.14.202-.311.356-.515.463v.049c.269.112.488.284.66.515.173.231.259.507.259.83 0 .321-.082.609-.245.861-.164.252-.39.451-.676.596a2.133 2.133 0 0 1-.97.218c-.416 0-.8-.119-1.154-.358ZM11.25 7.534l-.898.653-.45-.685 1.618-1.168h.62v5.508h-.89V7.534Z"
      />
      <path
        fill="#EA4335"
        d="M13.737 18 18 13.737l-2.132-.947-2.131.947-.947 2.131.947 2.132Z"
      />
      <path
        fill="#34A853"
        d="M3.316 15.868 4.263 18h9.474v-4.263H4.263l-.947 2.131Z"
      />
      <path
        fill="#4285F4"
        d="M1.421 0A1.42 1.42 0 0 0 0 1.421v12.316l2.132.947 2.131-.947V4.263h9.474l.947-2.131L13.737 0H1.42Z"
      />
      <path
        fill="#188038"
        d="M0 13.737v2.842a1.42 1.42 0 0 0 1.421 1.42h2.842v-4.262H0Z"
      />
      <path
        fill="#FBBC04"
        d="M13.737 4.263v9.474H18V4.263l-2.132-.947-2.131.947Z"
      />
      <path
        fill="#1967D2"
        d="M18 4.263V1.421A1.42 1.42 0 0 0 16.579 0h-2.842v4.263H18Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
