import * as React from 'react';

const SvgComponent: React.FC = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M3.401 14.167a.885.885 0 0 0-.878.891c0 .335.183.623.455.776l3.397 1.921a1.703 1.703 0 0 0 1.47.139l1.16-.34v-3.392c.005.005-5.6.005-5.604.005Z"
      />
      <path
        fill="url(#c)"
        d="M3.401 14.167a.885.885 0 0 0-.878.891c0 .335.183.623.455.776l3.397 1.921a1.703 1.703 0 0 0 1.47.139l1.16-.34v-3.392c.005.005-5.6.005-5.604.005Z"
      />
      <path
        fill="url(#d)"
        d="M7.77.072a1.684 1.684 0 0 0-.493-.071C7 0 6.723.058 6.474.192 6.422.216.949 3.354.949 3.354a1.596 1.596 0 0 0-.211.134c-.01.005-.015.01-.024.015-.042.033-.08.067-.122.1-.019.015-.038.034-.052.048-.023.024-.047.048-.065.072-.066.072-.179.225-.179.225-.194.29-.297.637-.296.992v8.336c0 .494.395.891.879.891a.794.794 0 0 0 .338-.072L2.78 13.2a1.31 1.31 0 0 0 .625-.69.97.97 0 0 0 .075-.278c0-.01.005-.024.005-.034a.79.79 0 0 0 .01-.105c0-.029.004-.053.004-.082V6.19c0-.388.164-.738.423-.982.122-.115.282-.215.47-.282.187-.072 4.632-1.71 4.632-1.71V.45L7.77.072Z"
      />
      <path
        fill="url(#e)"
        d="M7.77.072a1.684 1.684 0 0 0-.493-.071C7 0 6.723.058 6.474.192 6.422.216.949 3.354.949 3.354a1.596 1.596 0 0 0-.211.134c-.01.005-.015.01-.024.015-.042.033-.08.067-.122.1-.019.015-.038.034-.052.048-.023.024-.047.048-.065.072-.066.072-.179.225-.179.225-.194.29-.297.637-.296.992v8.336c0 .494.395.891.879.891a.794.794 0 0 0 .338-.072L2.78 13.2a1.31 1.31 0 0 0 .625-.69.97.97 0 0 0 .075-.278c0-.01.005-.024.005-.034a.79.79 0 0 0 .01-.105c0-.029.004-.053.004-.082V6.19c0-.388.164-.738.423-.982.122-.115.282-.215.47-.282.187-.072 4.632-1.71 4.632-1.71V.45L7.77.072Z"
      />
      <path
        fill="url(#f)"
        d="M12.774 1.566 7.846.096c.714.282 1.16.92 1.16 1.695 0 0-.004 14.1 0 14.44a1.752 1.752 0 0 1-1.16 1.667c.15-.043 4.928-1.447 4.928-1.447.71-.23 1.221-.9 1.221-1.7V3.266a1.777 1.777 0 0 0-1.221-1.7Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={3.01}
        x2={15.231}
        y1={15.63}
        y2={16.678}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F32B44" />
        <stop offset={0.6} stopColor="#A4070A" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={11.284}
        x2={5.859}
        y1={16.368}
        y2={15.902}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.4} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={7.948}
        x2={-1.726}
        y1={-0.62}
        y2={12.928}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#800600" />
        <stop offset={0.6} stopColor="#C72127" />
        <stop offset={0.728} stopColor="#C13959" />
        <stop offset={0.847} stopColor="#BC4B81" />
        <stop offset={0.942} stopColor="#B95799" />
        <stop offset={1} stopColor="#B85BA2" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={8.141}
        x2={6.092}
        y1={-0.901}
        y2={1.968}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.4} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={10.924}
        x2={10.924}
        y1={-0.916}
        y2={16.646}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB900" />
        <stop offset={0.166} stopColor="#EF8400" />
        <stop offset={0.313} stopColor="#E25C01" />
        <stop offset={0.429} stopColor="#DB4401" />
        <stop offset={0.5} stopColor="#D83B01" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
