import * as React from 'react';

const SvgComponent: React.FC = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#0364B8"
        d="M2.474 1.125h9.272c.201 0 .394.083.536.231a.807.807 0 0 1 .222.559v.897L7.278 4.5l-5.56-1.688v-.897c0-.21.079-.41.22-.559a.742.742 0 0 1 .536-.231Z"
      />
      <path
        fill="#0A2767"
        d="M.827 9.79A6.606 6.606 0 0 1 .638 9c0-.066.017-.13.049-.187a.367.367 0 0 1 .131-.137l.007-.004.002-.001 5.845-3.473a.782.782 0 0 1 .876 0l5.845 3.473.003.001.006.004c.055.033.1.08.132.137a.387.387 0 0 1 .048.187c-.046.268-.11.532-.189.79l-6.197 4.728L.827 9.79Z"
      />
      <path
        fill="#28A8EA"
        d="M4.953 2.813h3.775L9.818 4.5l-1.09 1.688-3.775 3.375H1.717V6.187l3.236-3.375Z"
      />
      <path fill="#0078D4" d="M12.504 2.813H8.728v3.374h3.776V2.813Z" />
      <path fill="#50D9FF" d="M4.953 2.813H1.717v3.374h3.236V2.813Z" />
      <path
        fill="#0364B8"
        d="m4.953 9.563 3.775-3.376h3.776v3.375l-3.776 3.376-5.842.994 2.067-4.37Z"
      />
      <path fill="#0078D4" d="M8.728 6.188H4.953v3.375h3.775V6.187Z" />
      <path fill="#064A8C" d="M12.504 9.563H8.728v3.374h3.776V9.563Z" />
      <path fill="#0078D4" d="M4.953 9.563H1.717v3.374h3.236V9.563Z" />
      <path
        fill="#0A2767"
        d="m7.008 14.185 6.36-4.837-.267-.49s-5.795 3.443-5.883 3.494a.275.275 0 0 1-.227-.006L1.095 8.84l-.268.49 6.18 4.854Z"
        opacity={0.5}
      />
      <path
        fill="#1490DF"
        d="m.818 9.325.008.004.001.001 5.845 3.473a.786.786 0 0 0 .788.051l-2.036 2.847-4.451 1.01v.003a.834.834 0 0 1-.246-.3.873.873 0 0 1-.089-.383V9c0 .066.017.13.049.187a.367.367 0 0 0 .131.138Z"
      />
      <path
        fill="#000"
        d="M.638 16.031v-.415l5.385-3.2.65.387a.785.785 0 0 0 .787.051l-2.036 2.847-4.451 1.01v.003a.834.834 0 0 1-.246-.3.872.872 0 0 1-.089-.383Z"
        opacity={0.05}
      />
      <path
        fill="#000"
        d="m.665 16.247 5.902-3.506.105.062a.784.784 0 0 0 .788.051l-2.036 2.847-4.451 1.011v.002a.846.846 0 0 1-.308-.467Z"
        opacity={0.1}
      />
      <path
        fill="#28A8EA"
        d="M13.393 9.332v-.006h.006l.016-.011a.36.36 0 0 0 .123-.135.378.378 0 0 0 .044-.18v7.031a.873.873 0 0 1-.236.597.78.78 0 0 1-.572.247H1.446a.78.78 0 0 1-.296-.062.56.56 0 0 1-.156-.084c-.011-.006-.017-.006-.022-.017l12.42-7.38Z"
      />
      <path
        fill="#000"
        d="M8.189 13.875V4.687c0-.198.076-.389.211-.53a.706.706 0 0 1 .508-.22h3.58v4.195l.905.538.003.002.007.003a.388.388 0 0 1 .18.325v5.625H8.907a.706.706 0 0 1-.508-.22.769.769 0 0 1-.211-.53Z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="M8.728 14.438V5.25c0-.199.077-.39.211-.53a.706.706 0 0 1 .508-.22h3.04v3.632l.906.538.003.002.007.003a.388.388 0 0 1 .18.325v6.188H9.446a.706.706 0 0 1-.508-.22.769.769 0 0 1-.21-.53Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M8.728 13.313V5.25c0-.199.077-.39.211-.53a.706.706 0 0 1 .508-.22h3.04v3.632l.906.538.003.002.007.003a.388.388 0 0 1 .18.325v5.063H9.446a.706.706 0 0 1-.508-.22.769.769 0 0 1-.21-.53Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M9.268 13.313V5.25c0-.199.076-.39.21-.53a.706.706 0 0 1 .509-.22h2.5v3.632l.906.538.003.002.007.003a.388.388 0 0 1 .18.325v5.063H9.986a.706.706 0 0 1-.508-.22.769.769 0 0 1-.211-.53Z"
        opacity={0.2}
      />
      <path
        fill="#0078D4"
        d="M17.178 4.5H9.987a.704.704 0 0 0-.509.22.766.766 0 0 0-.21.53v7.5c0 .199.075.39.21.53.135.141.318.22.509.22h7.19c.192 0 .374-.079.51-.22a.767.767 0 0 0 .21-.53v-7.5a.767.767 0 0 0-.21-.53.704.704 0 0 0-.51-.22Z"
      />
      <path
        fill="#fff"
        d="M15.811 7.576a2.32 2.32 0 0 0-.885-1.02 2.591 2.591 0 0 0-1.408-.368 2.41 2.41 0 0 0-1.301.348c-.372.231-.671.57-.862.975a3.26 3.26 0 0 0-.302 1.435 3.44 3.44 0 0 0 .311 1.5c.195.419.503.77.887 1.008.41.246.878.37 1.351.359.466.011.927-.11 1.331-.353.375-.231.678-.57.873-.977.209-.44.314-.925.306-1.416a3.532 3.532 0 0 0-.3-1.491Zm-.944 2.395a1.52 1.52 0 0 1-.497.672c-.228.166-.5.252-.779.244a1.326 1.326 0 0 1-.83-.252 1.46 1.46 0 0 1-.484-.674 2.701 2.701 0 0 1-.155-.936 2.959 2.959 0 0 1 .146-.948c.088-.275.25-.517.467-.698.237-.185.528-.279.824-.266.283-.007.562.079.796.247.228.17.404.405.51.677.23.622.232 1.312.003 1.935Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M18 0H0v18h18z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
