import classes from './PreferencesModal.schedule.calendar.module.scss';

import React, { Fragment } from 'react';
import Accordion from '@lobox/uikit/Accordion';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import type { FC } from 'react';
import { useProfilePanelState } from '@shared/hooks/useSettingsState';
import { EventsIntegration } from '@shared/components/molecules/EventsIntegration/EventsIntegration.component';

interface Props {
  onBack?: () => void;
  classNames?: {
    body?: string;
  };
  isNotModal?: boolean;
}

const ScheduleCalendarPreferencesModal: FC<Props> = ({
  onBack,
  classNames,
  isNotModal,
}) => {
  const { t } = useTranslation();
  const { closePanel } = useProfilePanelState();

  const onCloseProfilePanel = () => {
    closePanel();
  };

  const items = [
    {
      title: 'integrations',
      component: <EventsIntegration isEdit />,
    },
  ];

  const Tag = isNotModal ? Fragment : FixedRightSideModalDialog;

  return (
    <Tag onClickOutside={onCloseProfilePanel}>
      <Flex className={classes.root}>
        {!isNotModal && (
          <Flex className={classes.header}>
            <IconButton
              onClick={onBack}
              size="md20"
              name="chevron-left"
              colorSchema="transparent"
            />
            <Typography
              font="700"
              color="hover2_coal"
              className={classes.title}
              size={20}
              height={24}
            >
              {t('calendar_preferences')}
            </Typography>
            <Flex className={classes.fillerItem} />
          </Flex>
        )}
        <Flex className={cnj(classes.body, classNames?.body)}>
          <Accordion
            // @ts-ignore
            data={items.map((item, index) => ({
              key: item?.title,
              id: item?.title + index,
              divider: index !== items?.length - 1,
              isExpanded: true,
              header: ({ isExpanded }: { isExpanded: boolean }) => (
                <Flex className={classes.titleWrapper}>
                  <Typography
                    font="700"
                    color="thirdText"
                    size={16}
                    height={20}
                  >
                    {t(item?.title)}
                  </Typography>
                  <Flex flexDir="row">
                    <IconButton
                      name={isExpanded ? 'chevron-up' : 'chevron-down'}
                      size="md15"
                      className={classes.icon}
                      colorSchema="secondary"
                    />
                  </Flex>
                </Flex>
              ),
              content: () => item?.component,
            }))}
          />
        </Flex>
      </Flex>
    </Tag>
  );
};

export default ScheduleCalendarPreferencesModal;
